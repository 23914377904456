//const axios = require("axios");
import axios from "axios";
const XLSX = require("xlsx");
//const FormData = require("form-data");

class RequestStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    logoutIfSessionHasExpired = async (data) => {
        if (data === "auth error") {
            await this.rootStore.userStore.logout();
            throw new Error("AuthError");
        }
    };

    getDashboardData = async () => {
        try {
            let res = await axios.get("/api/utils/dashboard", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    forgotPassword = async (username, groupCode) => {
        try {
            let res = await axios.post("/api/session/forgotPassword", {
                username,
                groupCode,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updatePasswordViaEmail = async (
        username,
        group,
        password,
        resetPasswordToken
    ) => {
        try {
            let res = await axios.put("/api/session/updatePasswordViaEmail", {
                username,
                group,
                password,
                resetPasswordToken,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    resetPassword = async (resetPasswordToken, groupCode) => {
        try {
            const res = await axios.get("/api/session/resetPassword", {
                params: {
                    resetPasswordToken,
                    groupCode,
                },
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updatePasswordViaProfile = async (username, password) => {
        try {
            let res = await axios.put("/api/session/updatePasswordViaProfile", {
                username,
                password,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.passwordUpdate", username],
                date: Date.now(),
                type: "user",
                page: "Profile",
            };
            this.postLog(newLog);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendContactEmail = async (title, description) => {
        try {
            const res = await axios.post("/api/utils/sendContactEmail", {
                title,
                description,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["message.user",
                        this.rootStore.userStore.getUser().username,
                        "message.sentEmail",
                    ],
                date: Date.now(),
                type: "contact",
                page: "Contact",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentById = async (id) => {
        try {
            let res = await axios.get("/api/ad/" + id);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentById2 = async (letter, id) => {
        try {
            let res = await axios.get("/api/ad/?" + letter + "=" + id, {
                params: {
                    projection: "l$h$i&o",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegions = async (params) => {
        try {
            let res = await axios.get("/api/region", { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };



    getRegion = async (query) => {
        try {
            let res = await axios.get("/api/region/" + query, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionForStats = async (query) => {
        try {
            let res = await axios.get("/api/region/?q=" + query, {
                params: {
                    projection: "q$f$h$i",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionForStats2 = async (query) => {
        try {
            let res = await axios.get("/api/region/?q=" + query, {
                params: {
                    projection: "q$f$h$i",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionForTeam = async (name, adm) => {
        try {
            let res = await axios.get("/api/region/team", { params: { "name": name, "adm": adm } });
            return res.data;
        }
        catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getRegionID = async (name, adm) => {
        try {
            let res = await axios.get(
                "/api/region?f=" + name + "&h=" + adm,
                {}
            );
            if (res.data[0] === undefined) return "error";
            await this.logoutIfSessionHasExpired(res.data);
            return res.data[0]._id;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdGroupByCode = async (code) => {
        try {
            let res = await axios.get("/api/adGroup/TOT/?b=" + code, {});
            await this.logoutIfSessionHasExpired(res.data);
            if (res === undefined) return "error";
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsByGroupCode = async (code) => {
        try {
            let res = await axios.get("/api/ad/?l=" + code, {
                params: {
                    projection: "a",
                },
            });
            if (res.data[0] === undefined) return "error";
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUserData = async (username) => {
        try {
            let res = await axios.get(
                "/api/user/profile/?username=" + username,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateUserData = async (id, body, username) => {
        try {
            let res = await axios.put("/api/user/" + id, body);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.modifyAssignedRegions",
                        username],
                date: Date.now(),
                type: "assign",
                page: "Working Region",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    //-------------------------------------------------------
    sendUpdateAdIsNotNew = async (id, body) => {
        try {
            const res = await axios.put("/api/ad/" + id, {
                adIsSeen: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: ["messages.visited", id],
                date: Date.now(),
                idAd: id,
                type: "visited",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMaxCode = async () => {
        try {
            const res = await axios.get("/api/adGroup/idGroup", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateAdCode = async (id, body) => {
        try {
            const res = await axios.put("/api/ad/" + id, {
                adGroupCode: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.modifiedAd",
                        id,
                        "messages.belongsAdgroup",
                        body],
                date: Date.now(),
                idAd: id,
                idGroupAds: body,
                type: "ad",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateAdIsDiscarded = async (id, discards, noDiscards, idAd, code) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                discardedChildrenId: discards,
                childrenId: noDiscards,
            });
            let ress = [idAd];
            await axios.post("/api/adGroup/", {
                _id: code,
                childrenId: ress,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "sendUpdateAdIsDiscarded: Actualment no es fa servir aquesta funció, si hi ha algun log, comprobar referencies. S'ha actualizat els grups d'anuncis " +
                    id +
                    " i " +
                    code,
                date: Date.now(),
                idGroupAds: [id, code],
                type: "unused",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateProcesses = async (id, body) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                process: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.updatedProcess", id],
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateAdIsKeept = async (id, discards, noDiscards, iid) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                discardedChildrenId: discards,
                childrenId: noDiscards,
            });
            const resss = await axios.get("/api/ad/" + iid, {});
            const ress = await axios.put(
                "/api/adGroup/" + resss.data.adGroupCode,
                {}
            );
            if (ress.data.childrenId.length > 1) {
                for (let i in ress.data.childrenId) {
                    if (ress.data.childrenId[i] === iid) {
                        await axios.put(
                            "/api/adGroup/" + resss.data.adGroupCode,
                            {
                                discardedChildrenId:
                                    ress.data.discardedChildrenId,
                                childrenId: ress.data.childrenId,
                            }
                        );
                        break;
                    }
                }
            } else {
                await axios.delete(
                    "/api/adGroup/" + resss.data.adGroupCode,
                    {}
                );
            }
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    "sendUpdateAdIsKeept: Actualment no es fa servir aquesta funció, si hi ha algun log, comprobar referencies. S'ha actualitzat uns grups d'anuncis " +
                    id +
                    " i " +
                    +resss.data.adGroupCode,
                date: Date.now(),
                idGroupAds: [id, resss.data.adGroupCode],
                type: "unused",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    //--------------------------------------------------------------------------
    sendUpdateAdGroup = async (id, body, diff) => {
        try {
            let geo;
            let title;
            title = body.title;
            if (body.latitude === "" && body.longitude === "") geo = null;
            else geo = [parseFloat(body.longitude), parseFloat(body.latitude)];
            let res;
            if (geo !== null) {
                res = await axios.put("/api/adGroup/" + id, {
                    address: body.adAdress,
                    license: body.licenseNumber,
                    municipality: body.municipality,
                    roomType: body.typeOfRoom,
                    title: title,
                    tags: body.tags,
                    "host.name": body.ownerName,
                    geometry: {
                        type: "Point",
                        coordinates: geo,
                    },
                });
            } else {
                res = await axios.put("/api/adGroup/" + id, {
                    address: body.adAdress,
                    license: body.licenseNumber,
                    municipality: body.municipality,
                    roomType: body.typeOfRoom,
                    title: title,
                    tags: body.tags,
                    "host.name": body.ownerName,
                    geometry: null,
                });
            }
            let logMessage =
                ["messages.modifiedDataAdgroup",
                    id,
                    "messages.modifiedData"]
            if (diff.adAdress !== "noModified")
                logMessage = logMessage.concat(
                    ["messages.address", "messages.from", diff.adAdress, "messages.to", body.adAdress, "messages.space"])
            if (diff.code !== "noModified")
                logMessage = logMessage.concat(["messages.code", "messages.from", diff.code + "messages.to", body.code + "messages.space"]);
            if (diff.latitude !== "noModified")
                logMessage = logMessage.concat(["messages.latitude", "messages.from", diff.latitude, "messages.to", body.latitude, "messages.space"])
            if (diff.longitude !== "noModified")
                logMessage = logMessage.concat(["messages.longitude", "messages.from", diff.longitude, "messages.to", body.longitude, "messages.space"])
            if (diff.licenseNumber !== "noModified")
                logMessage = logMessage.concat(["messages.NIRTC", "messages.from", diff.licenseNumber, "messages.to", body.licenseNumber, "messages.space"])
            if (diff.municipality !== "noModified")
                logMessage = logMessage.concat(["messages.address", "messages.from", diff.municipality, "messages.to", body.municipality, "messages.space"])
            if (diff.ownerName !== "noModified")
                logMessage = logMessage.concat(["messages.owner", "messages.from", diff.ownerName, "messages.to", body.ownerName, "messages.space"])
            if (diff.title !== "noModified")
                logMessage = logMessage.concat(["messages.title", "messages.from", diff.title, "messages.to", body.title, "messages.space"])
            if (diff.typeOfRoom !== "noModified")
                logMessage = logMessage.concat(["messages.typeOfRoom", "messages.from", diff.typeOfRoom, "messages.to", body.typeOfRoom, "messages.space"])
            let addedTags = "";
            let removedTags = "";
            for (let phrase of diff.tags) {
                let splitted = phrase.split("/");
                if (splitted[0] === "Added")
                    addedTags += " " + splitted[1] + ",";
                else if (splitted[0] === "Removed")
                    removedTags += " " + splitted[1] + ",";
            }
            if (diff.tags != [])
                logMessage = logMessage.concat([
                    "messages.addedTags", addedTags, "messages.removedTags", removedTags]);

            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: logMessage,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    insertInProcessList = async (process) => {
        try {
            let res = await axios.post("/api/process", {
                _id: process._id,
                title: process.title,
                generalReport: process.generalReport,
                task: process.task,
                finished: process.finished,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.updatedProcess", process._id],
                date: Date.now(),
                type: "other",
                page: "Proces",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateProcessList2 = async (process2, group) => {
        try {
            for (let i in this.rootStore.groupAdsStore.processes) {
                if (
                    this.rootStore.groupAdsStore.processes[i]._id ===
                    process2._id
                ) {
                    this.rootStore.groupAdsStore.processes[i] = process2;
                }
            }
            let aux = "process." + process2._id;
            let res = await axios.put("/api/adGroup/" + group, {
                [aux]: {
                    _id: process2._id,
                    title: process2.title,
                    generalReport: process2.generalReport,
                    task: process2.task,
                    finished: process2.finished,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.updatedProcess",
                        process2._id,
                        "messages.fromAdgroup",
                        group],
                date: Date.now(),
                idGroupAds: group,
                type: "group",
                page: "Proces",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateProcessList = async (process) => {
        try {
            let intId = parseInt(process._id);
            let res = await axios.put("/api/process/" + intId, {
                _id: process._id,
                title: process.title,
                generalReport: process.generalReport,
                task: process.task,
                finished: process.finished,
            });
            await this.logoutIfSessionHasExpired(res.data);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.updatedProcess", process._id],
                date: Date.now(),
                type: "other",
                page: "Proces",
            };
            this.postLog(newLog);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateProcessState = async (process, group) => {
        try {
            parseInt(process._id);
            for (let i in this.rootStore.groupAdsStore.processes) {
                if (
                    this.rootStore.groupAdsStore.processes[i]._id ===
                    process._id
                ) {
                    this.rootStore.groupAdsStore.processes[i].finished = true;
                }
            }
            let aux = "process." + process._id;
            let res = await axios.put("/api/adGroup/" + group, {
                [aux]: {
                    _id: process._id,
                    title: process.title,
                    generalReport: process.generalReport,
                    task: process.task,
                    finished: true,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.updatedProcess",
                        process._id,
                        "messages.fromAdgroup",
                        group],
                date: Date.now(),
                idGroupAds: group,
                type: "group",
                page: "Proces",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    loginUser = async (user) => {
        try {
            console.log("ENTRANDO A LLAMADA LOGIN_USER");
            let res = await axios.post("/api/session/login", {
                username: user.username,
                password: user.password,
                groupCode: user.group,
            });
            console.log("AFTER LLAMADA LOGIN_USER");
            console.log(res)
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
            return "err";
        }
    };

    logoutUser = async () => {
        try {
            await axios.get("/api/session/logout");
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsById = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/ad/searchById", {
                params: {
                    value: text,
                    limit: 10,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByGroupId = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/adGroup", {
                params: {
                    b: "contains/$/" + text,
                    mySearch: true,
                    projection: "a$b",
                    limit: 10,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByLicenseCode = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    i: "contains/$/" + text,
                    mySearch: true,
                    projection: "i",
                    limit: 30,
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByHost = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    h: "contains/$/" + text,
                    mySearch: true,
                    projection: "h",
                    limit: 30,
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchRegionsByName = async (text, subregions) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/region", {
                params: {
                    f: "contains/$/" + text,
                    mySearch: true,
                    projection: "f$h$j",
                    mySubregions: subregions
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentsData = async (fr) => {
        try {
            let res = await axios.get(
                "/api/ad/?regionName=" + fr._id + "&adm=" + fr.adm,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getApartmentsDataForAGroups = async (ads, userFromGa) => {
        try {
            let user = await this.rootStore.userStore.getUser();
            if (!user && userFromGa) {
                user = userFromGa;
                user["id"] = userFromGa._id;
            }
            let res = await axios.get("/api/ad/adsAreInAssignedR", {
                params: {
                    userID: user.id,
                    adList: ads,
                    projection: "a",
                    limit: 1,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleApartmentsById = async (ids) => {
        try {
            let res = await axios.get("/api/ad", {
                params: {
                    a: "in/$/" + ids,
                    projection: "a$b$f$g$i$j$l$m$n$geo$com",
                    // limit: 20
                },
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getChildRegionsData = async (region) => {
        try {
            let newAdm = parseInt(region.adm.substr(region.adm.length - 1)) + 1;

            let queryParams = {
                parent: region._id,
                adm: region.adm,
                newAdm: "adm" + newAdm,
                limit: "99999",
                dir: "1",
                sort: "a",
            };

            let res = await axios.get("/api/region/regionsInMap", {
                params: queryParams,
            });

            while (res.data.length === 0 && newAdm < 6) {
                newAdm = newAdm + 1;
                let queryParams = {
                    parent: region._id,
                    adm: region.adm,
                    newAdm: "adm" + newAdm,
                    limit: "99999",
                    dir: "1",
                    sort: "a",
                };
                res = await axios.get("/api/region/regionsInMap", {
                    params: queryParams,
                });
            }
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getChildRegionsDataForStats = async (fr) => {
        try {
            let newAdm = parseInt(fr.adm.substr(fr.adm.length - 1)) + 1;
            let res = await axios.get(
                "/api/region/?parent=" +
                fr._id +
                "&adm=" +
                fr.adm +
                "&newAdm=adm" +
                newAdm +
                "&limit=99999&dir=1&sort=a",
                {
                    params: {
                        projection: "q$f$h$i",
                    },
                }
            );
            while (res.data.length === 0 && newAdm < 6) {
                newAdm = newAdm + 1;
                res = await axios.get(
                    "/api/region/?parent=" +
                    fr._id +
                    "&adm=" +
                    fr.adm +
                    "&newAdm=adm" +
                    newAdm +
                    "&limit=99999&dir=1&sort=a",
                    {
                        params: {
                            projection: "q$f$h$i",
                        },
                    }
                );
            }
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUserById = async (id, params) => {
        try {
            let res = await axios.get("/api/user/" + id, { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateUser = async (id, body, page, modified) => {
        try {
            const res = await axios.put("/api/user/" + id, body);
            await this.logoutIfSessionHasExpired(res.data);
            let logMessage = [
                "messages.modified",
                this.rootStore.userStore.getUser().username]
            if (modified.favAds === "modified")
                logMessage = [
                    "messages.modifiedFav",
                    this.rootStore.userStore.getUser().username]
            else if (modified.tabsOpen === "modified")
                logMessage = [
                    "messages.modifiedTab",
                    this.rootStore.userStore.getUser().username]
            else {
                if (modified.username !== "noModified")
                    logMessage = logMessage.concat(
                        ["messages.modifiedUsername ", "messages.of",
                            modified.username,
                            "messages.from",
                            body.username])
                if (modified.firstName !== "noModified")
                    logMessage = logMessage.concat(
                        ["messages.modifiedFirstName ", "messages.of",
                            modified.firstName,
                            "messages.from",
                            body.firstName])
                if (modified.lastName !== "noModified")
                    logMessage = logMessage.concat(
                        ["messages.modifiedLastName ", "messages.of",
                            modified.lastName,
                            "messages.from",
                            body.lastName])
                if (modified.group !== "noModified")
                    logMessage = logMessage.concat(
                        ["messages.modifiedGroup ", "messages.of",
                            modified.group,
                            "messages.from",
                            body.group])
                if (modified.email !== "noModified")
                    logMessage = logMessage.concat(
                        ["messages.modifiedEmail ", "messages.of",
                            modified.email,
                            "messages.from",
                            body.email])
            }
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: logMessage,
                date: Date.now(),
                type: "user",
                page: page,
            };
            this.postLog(newLog);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeUserClicked = async (id, userName, newValue) => {
        try {
            let query = "usersWhoClicked." + userName;
            let res = await axios.put("/api/ad/" + id, {
                [query]: newValue,
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getProcessList = async () => {
        try {
            let res = await axios.get("/api/process", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUsersFromGroup = async (group) => {
        try {
            let res = await axios.get("/api/user/team/?user=" + group, {});
		await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    // getInitialUsersFromGroup = async (filter, len, dir) => {
    //   let res = await axios.get(
    //     "/api/user/team/initial/?filter=" +
    //     filter +
    //     "&len=" +
    //     len +
    //     "&dir=" +
    //     dir,
    //     {}
    //   );
    //   await this.logoutIfSessionHasExpired(res.data);
    //   return res;
    // };

    // getUsersFromGroup = async (filter, last, unique, len, dir) => {
    //   let res = await axios.get(
    //     "/api/user/team/?filter=" +
    //     filter +
    //     "&last=" +
    //     last +
    //     "&unique=" +
    //     unique +
    //     "&len=" +
    //     len +
    //     "&dir=" +
    //     dir,
    //     {}
    //   );
    //   await this.logoutIfSessionHasExpired(res.data);
    //   return res;
    // };

    getInitialLogsFromGroup = async (filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/group/initial/?filter=" +
                filter +
                "&len=" +
                len +
                "&dir=" +
                dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLogsFromGroup = async (filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/group/?filter=" +
                filter +
                "&unique=" +
                unique +
                "&last=" +
                last +
                "&len=" +
                len +
                "&dir=" +
                dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    //Tots els ads de la BD pertanyen al mateix grup, especificat el dia 30 - 11
    getAdsFromGroup = async (filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/group/?filter=" +
                filter +
                "&unique=" +
                unique +
                "&last=" +
                last +
                "&len=" +
                len +
                "&dir=" +
                dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getFavsListPaginated = async (filters) => {
        try {
            let res = await axios.get("/api/ad/getFavsPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getHostsListPaginated = async (filters) => {
        try {
            // console.log("filters =", filters);

            let res = await axios.get("/api/ad/getHostsPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLicensesListPaginated = async (filters) => {
        try {
            let res = await axios.get("/api/ad/getLicensesPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdListPaginated = async (filters) => {
        try {
            let res = await axios.get("/api/ad/getAdsPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getPageIndexes = async (filters, maxPages) => {
        try {
            let res = await axios.get("/api/ad/getPageIndexes" + filters, {
                params: {
                    maxPages,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialAdsFromGroup = async (filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/group/initial?filter=" +
                filter +
                "&len=" +
                len +
                "&dir=" +
                dir,
                {}
            );

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLogsByUser = async (username, filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/user/?username=" +
                username +
                "&filter=" +
                filter +
                "&len=" +
                len +
                "&lastUnique" +
                unique +
                "&lastParam=" +
                last +
                "&dir=" +
                dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialLogsByUser = async (username, filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/log/user/initial/?username=" +
                username +
                "&filter=" +
                filter +
                "&len=" +
                len +
                "&dir=" +
                dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    // PAGINACIÓ LLICÈNCIES
    getLicensesByUser = async (username, filter, last, unique, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/license/?username=" +
                username +
                "&filter=" +
                filter +
                "&last=" +
                last +
                "&lastUnique" +
                unique +
                "&len=" +
                len +
                "&dir=" +
                dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialLicenseByUser = async (username, filter, len, dir) => {
        try {
            let res = await axios.get(
                "/api/ad/license/initial/?username=" +
                username +
                "&filter=" +
                filter +
                "&len=" +
                len +
                "&dir=" +
                dir,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsLicense = async (username, id) => {
        try {
            let res = await axios.get(
                "/api/ad/license/" + id + "/?username=" + username,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getHosts = async (params) => {
        try {
            let res = await axios.get(
                "/api/ad/host/?last=" +
                params.last +
                "&filter=" +
                params.filter +
                "&len=" +
                params.len +
                "&dir=" +
                params.dir +
                "&user=" +
                params.username +
                "&min=" +
                params.minPrice +
                "&max=" +
                params.maxPrice +
                "&platform=" +
                params.platform +
                "&state=" +
                params.state +
                "&license=" +
                params.license +
                "&minres=" +
                params.minRes +
                "&maxres=" +
                params.maxRes +
                "&lm=" +
                params.firstMonth +
                "&fm=" +
                params.lastMonth,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInitialHosts = async (params) => {
        try {
            let res = await axios.get(
                "/api/ad/host/initial/?filter=" +
                params.filter +
                "&len=" +
                params.len +
                "&dir=" +
                params.dir +
                "&user=" +
                params.username +
                "&min=" +
                params.minPrice +
                "&max=" +
                params.maxPrice +
                "&platform=" +
                params.platform +
                "&state=" +
                params.state +
                "&license=" +
                params.license +
                "&minres=" +
                params.minRes +
                "&maxres=" +
                params.maxRes +
                "&lm=" +
                params.firstMonth +
                "&fm=" +
                params.lastMonth,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsByHost = async (host) => {
        try {
            let res = await axios.get("/api/ad/?h=" + host, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getListByLicense = async (filter) => {
        try {
            let res = await axios.get("/api/ad/" + filter, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsTeam = async (username, region) => {
        try {
            let res = await axios.get("/api/ad/team/?username=" + username, {
                params: region,
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getGroupAds = async (letter, code) => {
        try {
            let res = await axios.get("/api/adGroup/?" + letter + "=" + code, {
                params: {
                    projection: "b$s",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsFromGroupAd = async (code) => {
        try {
            let res = await axios.get("/api/adGroup/ad/" + code, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getInfoMap = async (focusedRegion, filter, url, ascDesc, value) => {
        try {
            let queryParams =
                "/api/adGroup/mapAdGroups?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&len=" +
                filter.len +
                "&maxPages=" +
                filter.maxPages +
                "&workingUser=" +
                filter.workingUser +
                "&favAds=" +
                filter.favAds +
                "&notWorking=" +
                filter.notWorking +
                "&" +
                url;
            let res = await axios.get(queryParams, {
                params: {
                    ascDesc: ascDesc,
                    val: value,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getlistGroupAdsInMap = async (focusedRegion, boundaries, filter, url) => {
        try {
            let queryParams =
                "/api/adGroup/mapAdGroups?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&len=" +
                filter.len +
                "&maxPages=" +
                filter.maxPages +
                "&workingUser=" +
                filter.workingUser +
                "&favAds=" +
                filter.favAds +
                "&notWorking=" +
                filter.notWorking +
                "&" +
                url;

            if (!!boundaries) {
                queryParams += "&boundaries=" + JSON.stringify(boundaries);
            }
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllGroupAds = async () => {
        try {
            let res = await axios.get("/api/adGroup", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeWorking = async (code, username, page) => {
        try {
            let res = await axios.put("/api/adGroup/ads/" + code, {
                workingUser: username,
            });
            let message =
                "changeWorking: Actualment no es fa servir aquesta funció, si hi ha algun log, comprobar referencies. S'ha modificat el grup d'anunci " +
                code;
            if (username)
                message += " per a que ara el treballi l'usuari: " + username;
            else
                message +=
                    " per a que ara no el treballi l'usuari: " +
                    this.rootStore.userStore.getUser().username;
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: message,
                date: Date.now(),
                idGroupAds: code,
                type: "unused",
                page: page,
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchBiggestRegion = async (admNumber) => {
        try {
            let res = await axios.get("/api/region/IncGeo/?h=adm" + admNumber);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdStatisticsList = async (regionId = null) => {
        try {
            let res = await axios.get("/api/ad/statistics", {
                params: {
                    regionId: regionId,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    addTabToUser = async (id, body) => {
        try {
            const res = await axios.put("/api/user/" + id, {
                openedStatsTabs: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.modifiedWA",
                        this.rootStore.userStore.getUser().username],
                date: Date.now(),
                type: "user",
                page: "WA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdGroupById = async (id, params) => {
        try {
            if (!params) params = {};
            let res = await axios.get("/api/adGroup/" + id, params);
            /*let newLog = {
        user: this.rootStore.userStore.getUser().username,
        message: "S'ha modificat l'adGroup " + id,
        date: Date.now(),
        idGroupAds: id,
        type: "group",
      };
      this.postLog(newLog);*/
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleApartmentsByIdForAdGroups = async (ids) => {
        try {
            let res = await axios.get("/api/ad/ads", {
                params: {
                    a: "in/$/" + ids,
                    limit: 9999999,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleAds = async (ids, params) => {
        try {
            if (!params.a) params["a"] = "in/$/" + ids;
            let res = await axios.get("/api/ad/ads", {
                params: params,
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMultipleAdsForGroupEditor = async (ids, params) => {
        try {
            if (!params.a) params["a"] = "in/$/" + ids;
            let res = await axios.get("/api/ad/adsEditGroup", {
                params: params,
            });
            await this.logoutIfSessionHasExpired(res.data);

            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLastAdGroup = async () => {
        try {
            let res = await axios.get("/api/adGroup/last", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    newAdGroup = async (adGroup) => {
        try {
            let res = await axios.post("/api/adGroup/", adGroup);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: ["messages.addAdgroup", adGroup._id],
                date: Date.now(),
                idGroupAds: adGroup._id,
                type: "ad",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    modifyAdGroup = async (adGroup) => {
        try {
            let res = await axios.put("/api/adGroup/" + adGroup._id, adGroup);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: ["messages.modifyAdgroup", adGroup._id],
                date: Date.now(),
                idGroupAds: adGroup._id,
                type: "ad",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    modifyAdList = async (ads, gaId) => {
        try {
            let res = await axios.put("/api/ad/list", { ids: ads, ga: gaId });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.modifiedAd",
                        (JSON.stringify(ads)),
                        "messages.belongsAdgroup",
                        gaId],
                date: Date.now(),
                idAd: ads,
                idGroupAds: gaId,
                type: "ad",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    modifyAdListIndividual = async (ads, idArray) => {
        try {
            let res = await axios.put("/api/ad/list", {
                ids: ads,
                ini: idArray[0],
                last: idArray[idArray.length - 1],
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.modifiedAds",
                        JSON.stringify(ads),
                        "messages.partOfOwn",
                        JSON.stringify(idArray)],
                date: Date.now(),
                idAd: ads,
                idGroupAds: JSON.stringify(idArray),
                type: "ad",
                page: "Desagrupar anuncis",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    insideMyAssignedRegionsForWA = async (id, name, adm) => {
        try {
            let user = this.rootStore.userStore.getUser();
            let res = await axios.get(
                "/api/region/subR/?parentId=" +
                id +
                "&adm=" +
                adm +
                "&limit=99999&dir=1&sort=a",
                {
                    params: {
                        projection: "q$h$i",
                        userFromWA: user.id,
                    },
                }
            );
            await this.logoutIfSessionHasExpired(res.data);
            if (res.data === true) return true;
            else return false;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdByIdForUsersWhoClicked = async (id) => {
        try {
            let res = await axios.get("/api/ad/" + id, {
                params: {
                    projection: "a$s$l$m$f$t",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllUsers = async (params) => {
        try {
            let res = await axios.get("api/user", { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeWorkingNew = async (id, username, page, arrayWorking, realUser) => {
        try {
            let usernameToAdd = realUser
            if (username)
                usernameToAdd = username
            let res = await axios.put("/api/adGroup/workingUser/" + id + "?user=" + usernameToAdd, {
                workingUser: arrayWorking,
            });
            let message = ["messages.modifyAdgroup", id];
            console.log(message)
            if (username)
                message = message.concat(["messages.workUser", username]);
            else
                message = message.concat(
                    ["messages.notWorkUser",
                        this.rootStore.userStore.getUser().username]);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: message,
                date: Date.now(),
                idGroupAds: id,
                type: "assign",
                page: page,
            };
            console.log(message)
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllProcesses = async (query) => {
        try {
            let res = await axios.get("/api/adGroup/" + query, {
                params: {
                    projection: "b$o",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    postLog = async (newLog) => {
        try {
            console.log(newLog.message);
            let res = await axios.post("/api/log/", newLog);
            await this.logoutIfSessionHasExpired(res.data);
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    // getAllLogs = async () => {
    //   try {
    //     let res = await axios.get("/api/log/", {params:{limit:400}});
    //     await this.logoutIfSessionHasExpired(res.data);
    //     return res;
    //   } catch (err) {
    //     console.log("An error ocurred while executing the action requested");
    //   }
    // }

    getAllAds = async (params) => {
        try {
            let res = await axios.get("/api/ad/", { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLogsForActivity = async (url) => {
        try {
            let res = await axios.get("/api/log/activity/" + url, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) { }
    };

    getLogsForActivityNoSuperuser = async (url) => {
        try {
            let res = await axios.get("/api/log/activityNoSuper/" + url, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) { }
    };

    registerWebCredentials = async (username, formData) => {
        try {
            console.log("REQUEST STORE formData =", formData);
            //console.log("ENTERING REQUEST REGISTER WEB CREDENTIALS")
            let res = await axios.put(
                "/api/user/registerWebCredentials/" + username,
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.modifiedWebCredentials",
                        username],
                date: Date.now(),
                type: "user",
                page: "Profile",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            //console.log("EXIT REQUEST REGISTER WEB CREDENTIALS")
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    register = async (body) => {
        try {
            //console.log("entering Register in Request Store")
            let res = await axios.get("/api/session/registerFromPage", {
                params: body,
            });
            /*{
        email:body.email,
        username: body.username,
        isSuperuser: body.isSuperuser,
        isSupervisor: body.isSupervisor,
        password:body.password,
        firstName: body.firstName,
        lastName: body.lastName,
        group: body.group,
        regions: body.regions
      });
      */
            await this.logoutIfSessionHasExpired(res.data);
            if (!res.data.error) {
                let newLog = {
                    user: this.rootStore.userStore.getUser().username,
                    message:
                        ["messages.createdUser",
                            body.username],
                    date: Date.now(),
                    type: "user",
                    page: "New User",
                };
                this.postLog(newLog);
            }
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendAirbnbMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendAirbnbMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.user",
                        this.rootStore.userStore.getUser().username,
                        "messages.sent", "messages.airbnb"],
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log("error is:", error);

            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendHomeawayMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendHomeawayMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.user",
                        this.rootStore.userStore.getUser().username,
                        "messages.sent", "messages.homeaway"],
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendTripadvisorMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendTripadvisorMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.user",
                        this.rootStore.userStore.getUser().username,
                        "messages.sent", "messages.tripadvisor"],
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendLeboncoinMessage = async (formData) => {
        try {
            console.log("send Leboncoin message");

            let res = await axios.post(
                "/api/utils/sendLeboncoinMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.user",
                        this.rootStore.userStore.getUser().username,
                        "messages.sent", "messages.leboncoin"],
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendHousetripMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendHousetripMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.user",
                        this.rootStore.userStore.getUser().username,
                        "messages.sent", "messages.housetrip"],
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendNiumbaMessage = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/sendNiumbaMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.user",
                        this.rootStore.userStore.getUser().username,
                        "messages.sent", "messages.niumba"],
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendRentaliaMessage = async (formData) => {
        try {
            console.log("send rentalia message");

            let res = await axios.post(
                "/api/utils/sendRentaliaMessage/",
                formData
            );
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.user",
                        this.rootStore.userStore.getUser().username,
                        "messages.sent", "messages.rentalia"],
                date: Date.now(),
                type: "user",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getPlatforms = async () => {
        try {
            let res = await axios.get("/api/hired/platforms/", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getStates = async () => {
        try {
            let res = await axios.get("/api/hired/states/", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByTag = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/adGroup/searchByTag", {
                params: {
                    value: text,
                    limit: 10,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    updateNotesAndLastModification = async (message, id) => {
        try {
            const res = await axios.put("/api/adGroup/" + id, {
                "state.message": message,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.updatedComment",
                        id,
                        "messages.withComment",
                        message.notes],
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    /*
  let aux = "process." + process2._id;
      let res = await axios.put("/api/adGroup/" + group, {
        [aux]: {
          _id: process2._id,
          title: process2.title,
          generalReport: process2.generalReport,
          task: process2.task,
          finished: process2.finished,
        },
  */

    updateStateGA = async (updatedState, id) => {
        try {
            const res = await axios.put("/api/adGroup/state/" + id, {
                state: updatedState,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.updatedState",
                        id,
                        "messages.actualState",
                        updatedState.name],
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getStateGA = async (id) => {
        try {
            const res = await axios.get("/api/adGroup/" + id, {
                params: {
                    projection: "p$q$r$t$o",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAllStates = async (group) => {
        try {
            const res = await axios.get("/api/hired/concrete", {
                params: {
                    group: group,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    /*
  let res = await axios.get("/api/ad/?l=" + code, {
        params: {
          projection: "a",
  */

    getAllUsersExceptSuperusers = async (params) => {
        try {
            let res = await axios.get("api/user/noSuperuser", {
                params: params,
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (err) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchGroupsForMerge = async (text, list) => {
        try {
            let res = await axios.get("/api/adGroup/searchById", {
                params: {
                    value: text,
                    nin: list,
                    limit: 20,
                    //municipality:true,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    mergeAdGroup = async (left, right) => {
        try {
            let res = await axios.put("/api/adGroup/mergeGroups", {
                query: {
                    left: left.id,
                    right: right.id,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.modifyAdgroup",
                        left.id,
                        "messages.and",
                        right.id,
                        "messages.allAdsOf",
                        right.id,
                        "messages.assignedTo",
                        left.id],
                date: Date.now(),
                idGroupAds: [left.id, right.id],
                type: "ad",
                page: "Merge",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    newGroupFromMergeAds = async (left, lChilds, right, rChilds) => {
        try {
            console.log(left, lChilds, right, rChilds);
            let res = await axios.post("/api/adGroup/newFromMerge", {
                query: {
                    left: left,
                    right: right,
                    lChilds: lChilds,
                    rChilds: rChilds,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.newAdGroup",
                        res.data._id,
                        "messages.withAdsFrom",
                        left,
                        "messages.and",
                        right,
                        "messages.dot"],
                date: Date.now(),
                idGroupAds: [res.data._id, left.id, right.id],
                idAd: lChilds.concat(rChilds),
                type: "ad",
                page: "Merge",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    mergeTransferAds = async (left, right, rChilds) => {
        try {
            console.log(left, right, rChilds);
            let res = await axios.put("/api/adGroup/mergeTransferAds", {
                query: {
                    left: left.id,
                    right: right.id,
                    rChilds: rChilds,
                },
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.transferedAds",
                        rChilds,
                        "messages.from",
                        right.id,
                        "messages.to",
                        left.id],
                date: Date.now(),
                idGroupAds: [left.id, right.id],
                idAd: rChilds,
                type: "ad",
                page: "Merge",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getRegionInfoForSearch = async (value) => {
        try {
            let res = await axios.get(
                "/api/region/regionSearch/?f=" + value.name,
                {
                    params: {
                        parentName: value.parentName,
                        projection: "q$f$h$j",
                    },
                }
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByHostId = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    o: "contains/$/" + text,
                    mySearch: true,
                    limit: 30,
                    projection: "o",
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    transferAds = async (receiver, ads) => {
        try {
            let res = await axios.put("/api/adGroup/transferAds", {
                query: {
                    group: receiver,
                    ads: ads,
                },
            });
            console.log("TEST - CREATE LOG");
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message:
                    ["messages.transferedAds",
                        JSON.stringify(ads),
                        "messages.to",
                        receiver],
                date: Date.now(),
                idGroupAds: receiver,
                idAd: ads,
                type: "ad",
                page: "Transfer Ads",
            };
            await this.postLog(newLog);
            console.log("LOG - SENDED");
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getMunicipality = async (groupId) => {
        try {
            let res = await axios.get(
                "/api/adGroup/getMunicipality?b=" + groupId,
                {}
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchDiscardedAds = async (discardedAds) => {
        try {
            let res = await axios.get(
                "/api/adGroup/getGroupDiscardedAds?ads=" +
                JSON.stringify(discardedAds)
            );
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    searchAdsByIdRegex = async (text) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let user = await this.rootStore.userStore.getUser();
            let res = await axios.get("/api/ad", {
                params: {
                    a: "contains/$/" + text,
                    mySearch: true,
                    projection: "a$f$l",
                    limit: 10,
                    userID: user.id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getCityHallData = async (city) => {
        try {
            let res = await axios.get("/api/cityHall/getCityHall", {
                params: {
                    name: city,
                    // projection: "l$f",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getCityHallName = async (city) => {
        try {
            let res = await axios.get("/api/region/getCityHall", {
                params: {
                    name: city,
                    // projection: "l$f",
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getUserAssignedRegions = async (id) => {
        try {
            let res = await axios.get("/api/user/getAssignedRegions", {
                params: { id: id, projection: "f" },
            });

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getExtraProducts = async () => {
        try {
            let res = await axios.get("/api/hired/extra", {});

            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    changeWorkingNewForGroupEditor = async (id, username, page, actualUser, workingUser) => {
        try {
            let res = await axios.put("/api/adGroup/workingUser/" + id, {
                workingUser: workingUser,
            });
            let message = ["messages.modifyAdgroup", id];
            if (username)
                message = message.concat(["messages.dot", "messages.space",
                    "messages.user",
                    username,
                    "messages.assignedAdgroupCreation"]);
            else
                message = message.concat(["messages.dot", "messages.space",
                    "messages.user",
                    username,
                    "messages.notAssignedAdgroupCreation"]);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: message,
                date: Date.now(),
                idGroupAds: id,
                type: "ad",
                page: page,
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };
    getMail = async (id) => {
        try {
            let res = await axios.get("/api/mail/" + id, {});
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendTicket = async (ticket) => {
        try {
            let res = await axios.post("/api/mail/sendTicket", {
                ticket: ticket,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
            console.log(
                error
            )
        }
    };
    /*
  sendFileForTicket = async (file, ticketID) => {
    try {
      let formData = new FormData();
      formData.append("manualName", file, file.name);
      for (let key of formData.entries()) {
      }
      let res = await axios.put(
        "/api/mail/sendFileForTicket",
        {body:formData},
        {
          headers: formData.getHeaders()
          //  {"Content-Type": `multipart/form-data; boundary=${formData._boundary}`,}
          
        }
      );

      return res;
    } catch (error) {
      console.error(error);
      console.log("An error ocurred while executing the action requested");
    }
  };*/
    sendFileTicket = async (formData) => {
        try {
            let res = await axios.post(
                "/api/mail/sendFileTicket", formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            return res;
        } catch (error) {
            console.log("An error has occurred while saving a file in a server");
        }
    }

    sendConfirmationMail = async (subject, text, username, group, html) => {
        try {
            //console.log(text, username)
            let res = await axios.post("/api/mail/sendConfirmationMail", {
                subject: subject,
                text: text,
                username: username,
                group: group,
                html: html,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendBasicMail = async (subject, text, username, group, html) => {
        try {
            let res = await axios.post("/api/mail/sendBasicMail", {
                subject: subject,
                text: text,
                username: username,
                group: group,
                html: html,
            });
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while sending the basic email"
            );
        }
    };

    getGroupAdsErrorCount = async (id) => {
        try {
            let res = await axios.get("/api/adGroup/getErrorCount", {
                params: {
                    id: id,
                },
            });
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    generateExcel = async (userName, data) => {
        try {
            console.log(data)
            let d = new Date().toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
            });
            d = d.replaceAll("/", "-");

            const workSheet = XLSX.utils.json_to_sheet(data);
            var wscols = [{ wch: 12 }, { wch: 20 }, { wch: 50 }, { wch: 20 }];

            workSheet["!cols"] = wscols;
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "information");
            let buf = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer",
            });
            const url = window.URL.createObjectURL(new Blob([buf]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", userName + "_" + d + ".xlsx");
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    generateExcelOTA = async (fileName, data) => {
        try {
            console.log(data)
            let d = new Date().toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
            });
            d = d.replaceAll("/", "-");

            const workSheet = XLSX.utils.json_to_sheet(data);
            var wscols = [{ wch: 8 }, { wch: 20 }, { wch: 20 }, { wch: 75 }, { wch: 20 }, { wch: 50 }];

            workSheet["!cols"] = wscols;
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "information");
            let buf = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer",
            });
            const url = window.URL.createObjectURL(new Blob([buf]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName + ".xlsx");
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsForExcel = async (focusedRegion, url) => {
        try {
            let queryParams =
                "/api/adGroup/getExcelAds?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&" +
                url;
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getCountStats = async (id) => {
        try {
            let queryParams = "/api/ad/getCountStats";
            let res = await axios.get(queryParams, {
                params: {
                    id: id,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    uploadImage = async (formData) => {
        try {
            //await axios.get("/api/mail/googleAuth")
            /*let res = await axios.get("/api/mail/credentialAuth2",{
                params: {url: "http://127.0.0.1:3000/oauth?code=4/0AeaYSHBWAnJmObrtCW8_LSWs3be6fAj3NWwF9dcAFEcZmwzw_hvGa_Q2_uqdEOxWqGAp7Q&scope=https://mail.google.com/"}
            })*/
            let res = await axios.post("/api/mail/uploadImage", formData, { headers: { "Content-Type": "multipart/form-data" } })
            let imageUrl = res.data.imageUrl
            res = await axios.get("/api/mail/getImage", { params: { imageUrl: imageUrl }, responseType: "arraybuffer" });
            return { image: res, url: imageUrl };
        } catch (error) {
            console.log(
                "An error ocurred while uploading the image:", error
            );
        }

    }

    getSTPLogin = async (ad) => {
        try {
            console.log(ad)
            let res = await axios.get(
                "/api/user/getSTPLogin",
                {
                    params: {
                        id: ad,
                        download: false
                    }
                }
            );
            return res;
        }
        catch (error) {
            console.log(
                "An error ocurred while uploading the image:", error
            )
        }
    }

    getSTPDownload = async (ad) => {
        try {
            console.log(ad)
            let res = await axios.get(
                "/api/user/getSTPLogin",
                {
                    params: {
                        id: ad,
                        download: true
                    }
                }
            );
            return res;
        }
        catch (error) {
            console.log(
                "An error ocurred while uploading the image:", error
            )
        }
    }



    sendUpdateAdSTP = async (id, body) => {
        try {
            const res = await axios.put("/api/ad/" + id, {
                stp: body,
            });
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: ["messages.modifiedAd", id],
                date: Date.now(),
                idAd: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };
    searchComplianceForGroupAd = async (id) => {
        try {
            let res = await axios.get("/api/ad/getComplianceForGroupAd", {
                params: {
                    id: id,
                },
            })
            return (res)
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getAdsForLicenseMaps = async (focusedRegion, url) => {
        try {
            let queryParams =
                "/api/adGroup/getExcelAds?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&" +
                url;
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getSTPCountForGroupAds = async (id) => {
        try {
            const res = await axios.get("/api/ad/groupAdsSTP", { params: { id: id } })
            return res
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }
    getLicenseAdsForLicenseMaps = async (focusedRegion, url) => {
        try {
            let queryParams =
                "/api/adGroup/getExcelAds?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&" +
                url;
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdsMapForLicense = async (focusedRegion, filter, url) => {
        try {
            let queryParams =
                "/api/adGroup/mapAdGroups?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&len=" +
                filter.len +
                "&maxPages=" +
                filter.maxPages +
                "&workingUser=NoFilter&favAds=NoFilter&notWorking=NoFilter&" +
                url;
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getExtraAdsMapForLicense = async (focusedRegion, filter, url) => {
        try {
            let queryParams =
                "/api/license/adsLicense?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm +
                "&len=" +
                filter.len +
                "&maxPages=" +
                filter.maxPages +
                "&workingUser=NoFilter&favAds=NoFilter&notWorking=NoFilter&" +
                url;
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getLowerRegions = async (focusedRegion) => {
        try {
            let res = await axios.get("api/region/getLowerRegionsInside?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm,
                {}
            )
            console.log(res)
            return res
        } catch (error) {
            console.log(error)
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    findGroupsOfLicense = async () => {
        try {
            let res = await axios.get("/api/license/getAllGroups",
                {}
            )
            return res
        } catch (error) {
            console.log(error)
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getCensusTableData = async (focusedRegion) => {
        try {
            let res = await axios.get("/api/region/getCensusTableData?region=" +
                focusedRegion._id,
                {}
            )
            return res
        } catch (error) {
            console.log(error)
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    countByRoomType = async (focusedRegion) => {
        try {
            let res = await axios.get("/api/ad/countByRoomType?regionName=" +
                focusedRegion._id +
                "&adm=" +
                focusedRegion.adm,
                {}
            )
            return res
        } catch (error) {
            console.log(error)
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getModifiedAdsByUser = async (username) => {
        try {
            console.log(username)
            let queryParams =
                "/api/adGroup/getModifiedAdsByUser?user=" +
                username
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getModifiedAds = async () => {
        try {
            let queryParams =
                "/api/adGroup/getModifiedAds"
            let res = await axios.get(queryParams, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    generateExcelForModifiedAds = async (name, data) => {
        try {
            console.log("data request: ", data)
            let d = new Date().toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
            });
            d = d.replaceAll("/", "-");

            const workSheet = XLSX.utils.json_to_sheet(data);
            var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 40 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }];

            workSheet["!cols"] = wscols;
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "information");
            let buf = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer",
            });
            const url = window.URL.createObjectURL(new Blob([buf]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name + "_" + d + ".xlsx");
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAdGroupCodeByHostOfAd = async (id, host, adm, region) => {
        try {
            let queryParams =
                "/api/ad/getAdGroupCodeByHostOfAd"
            let res = await axios.get(queryParams, { params: { id: id, host: host, adm: adm, region: region } });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getUsersWithRegions = async (regions) => {
        try {
            let res = await axios.get("/api/user/getWithRegions", { params: { regions: regions } })
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    createNewClient = async (body) => {
        try {
            console.log("Arriba al request: ", body);
            let res = await axios.post("/api/hired/createClient", body);
            console.log(res);
        } catch (error) {
            console.log("An error has occurred while creating a new client");
        }
    }

    uploadFileServer = async (formData) => {
        try {
            let res = await axios.post(
                "/api/utils/uploadFileServer", formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            return res;
        } catch (error) {
            console.log("An error has occurred while saving a file in a server");
        }
    }

    getAllHiredRegions = async () => {
        try {
            let res = await axios.get("api/hired", {});
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log("An error has occurred while getting the hired regions");
        }
    }
    countAdsByRegion = async (region, adm) => {
        try {
            let res = await axios.get("/api/ad/countAdsByRegion", { params: { name: region, adm: adm } });
            await this.logoutIfSessionHasExpired(res);
            return res.data

        }
        catch (error) {
            console.log("An error ocurred while executing the action requested");
        }
    }

    countAdGroupsByRegion = async (region, adm) => {
        try {
            let res = await axios.get("/api/ad/countAdGroupsByRegion", { params: { name: region, adm: adm } });
            await this.logoutIfSessionHasExpired(res);
            return res.data["r"];

        }
        catch (error) {
            console.log("An error ocurred while executing the action requested");
        }
    }

    getAssignedCount = async () => {
        try {
            let res = await axios.get("/api/adGroup/countAssigned");
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log("An error ocurred while executing the action requested"
            );
        }
    };

    getFavsCount = async () => {
        try {
            let res = await axios.get("/api/adGroup/countFavs");
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log("An error ocurred while executing the action requested"
            );
        }
    };



    getRegionsFromGroup = async (params) => {
        try {
            let res = await axios.get("/api/region/getFromGroup", { params: params });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while getting the number of assigned ads"
            );
        }
    }

    getAdGroupById = async (groupKey) => {
        try {
            let res = await axios.get("/api/adGroup/getById/" + groupKey);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while getting the adGroup"
            );
        }
    }


    getAdsByGroup = async (groupCode) => {
        try {
            let res = await axios.get("/api/ad/getByGroupKey", {
                params: {
                    value: groupCode,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while getting the ads of the specified adGroup"
            );
        }
    }

    searchRegionsFromGroupByName = async (text, group) => {
        try {
            text = text.replace("(", "");
            text = text.replace(")", "");
            let res = await axios.get("/api/region/getFromGroup", {
                params: {
                    f: "contains/$/" + text,
                    mySearch: true,
                    projection: "f$h$j",
                    limit: 10,
                    group: group,
                },
            });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAssignedListPaginated = async (filters) => {
        try {
            let res = await axios.get("/api/ad/getAssignedPage" + filters, {});
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    getAssignedList = async (user) => {
        try {
            let res = await axios.get("/api/adGroup/getAssignedList", { params: { user: user } });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getAdGroupsToAssign = async (regions, start, amount) => {
        try {
            let res = await axios.get("/api/ad/getAdgroupsToAssign", { params: { regions: regions, start: start, amount: amount } });
            await this.logoutIfSessionHasExpired(res.data);
            return res.data;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    deleteWorkingUser = async (username, id) => {
        try {
            let res = await axios.put("/api/adGroup/deleteWorkingUser", { params: { username: username, id: id } })
            let message = ["messages.modifyAdgroup", id];
            message =
                message.concat(["messages.notWorkUser", username]);
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: message,
                date: Date.now(),
                idGroupAds: id,
                type: "assign",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        }
        catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getAdsWithWorkingUser = async (users) => {
        try {
            let res = await axios.get("/api/ad/getAdsWithWorkingUser", { params: { users: users } });
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getAdsWithoutWorkingUser = async () => {
        try {
            let res = await axios.get("/api/ad/getAdsWithoutWorkingUser");
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    generateExcelForSupervisor = async (name, data) => {
        try {
            console.log(data)
            let d = new Date().toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
            });
            d = d.replaceAll("/", "-");

            const workSheet = XLSX.utils.json_to_sheet(data);
            var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 50 }, { wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }];

            workSheet["!cols"] = wscols;
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "information");
            let buf = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer",
            });
            const url = window.URL.createObjectURL(new Blob([buf]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name + "_" + d + ".xlsx");
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }
    updateAdStatesAdGroup = async (id, body) => {
        try {
            let res;
            res = await axios.put("/api/adGroup/" + id, {
                historic: body,
            });
            let logMessage =
                ["messages.updatedState", id];
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: logMessage,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    countAssignedAdsRegion = async (id, user, adm) => {
        try {
            let res = await axios.get("/api/ad/countAssignedAdsRegion", { params: { id: id, user: user, adm: adm } })
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }
    updateAdStatesGroup = async (id, body) => {
        try {
            let res;
            res = await axios.put("/api/adGroup/" + id, {
                historicGroup: body,
            });
            let logMessage =
                ["messages.updatedState", id];
            let newLog = {
                user: this.rootStore.userStore.getUser().username,
                message: logMessage,
                date: Date.now(),
                idGroupAds: id,
                type: "group",
                page: "GA",
            };
            this.postLog(newLog);
            await this.logoutIfSessionHasExpired(res.data);
            return res;
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    };

    sendUpdateAdStateName = async (id, state) => {
        try {
            let res = await axios.put("/api/ad/state", {
                "id": id,
                "state": state
            })
            return res
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getUserStats = async () => {
        try {
            let res = await axios.get("/api/adGroup/getUserStats")
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    countAdsBD = async () => {
        try {
            let res = await axios.get("/api/ad/countAdsBD")
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getLogsForExcel = async (url) => {
        try {
            let res = await axios.get("/api/log/logsForExcel/" + url)
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getActivityStats = async (url) => {
        try {
            let res = await axios.get("/api/log/activityStats/" + url)
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getActiveUsers = async (url) => {
        try {
            let res = await axios.get("/api/log/activeUsers/")
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getActivityEachUser = async () => {
        try {
            let res = await axios.get("/api/log/activityEachUser/")
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getLogsLastAssigned = async () => {
        try {
            let res = await axios.get("/api/log/logsLastAssigned/")
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }

    getLogLastAssigned = async (user, adgroup) => {
        try {
            let res = await axios.get("/api/log/logLastAssigned/", { params: { user: user, adgroup: adgroup } })
            await this.logoutIfSessionHasExpired(res.data)
            return res.data
        } catch (error) {
            console.log(
                "An error ocurred while executing the action requested"
            );
        }
    }
}





export default RequestStore;
